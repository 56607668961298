<div class="container">
    <div class="card w-50 mx-auto rounded" *ngIf="manager.errDate">
        <div class="card-body bg-sl text-white">
            <h1 class="card-title">C'est embarassant! <i class="fas fa-bell ml-4" aria-hidden="true"></i></h1>
            <p class="card-text h6 text-white">Il se trouve qu'il y'a une erreur avec la date de votre ordinateur</p>
            <p class="card-text h6 text-white">Veuillez vérifier et corriger la date ou contacter un technicien</p>
        </div>
    </div>

    <div class="card w-50 mx-auto rounded" *ngIf="manager.licencesComplete">
        <div class="card-body bg-danger text-white">
            <h1 class="card-title">Ouppsss! <i class="fas fa-exclamation-triangle ml-4" aria-hidden="true"></i></h1>
            <p class="card-text h6 text-white">Il se trouve qu'il y'a une erreur avec la date de votre ordinateur</p>
            <p class="card-text h6 text-white">Veuillez vérifier et corriger la date ou contacter un technicien</p>
        </div>
    </div>
</div>