import { BrowserModule } from '@angular/platform-browser';
import { NgModule, NO_ERRORS_SCHEMA, LOCALE_ID } from '@angular/core';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { Service } from './services/service';
import { UsualsFunctionsService } from './services/usuals-functions.service';
// import { StorageServiceModule} from 'angular-webstorage-service';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { registerLocaleData, DatePipe, CurrencyPipe } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import localeFrExtra from '@angular/common/locales/extra/fr';

registerLocaleData(localeFr, 'fr-FR', localeFrExtra);
import { MaterialModule } from './material/material.module';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { LoginComponent } from './login/login.component';
import { LicenceComponent } from './licence/licence.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    LicenceComponent
  ],
  imports: [
    BrowserModule,
    MDBBootstrapModule.forRoot(),
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    // StorageServiceModule,
    BrowserAnimationsModule,
    MaterialModule
  ],
  providers: [
    Service,
    DatePipe,
    CurrencyPipe,
    UsualsFunctionsService,
    
    {
      provide: LOCALE_ID,
      useValue: 'fr-FR' // 'de-DE' for Germany, 'fr-FR' for France ...
    }
  ],
  bootstrap: [AppComponent],
  schemas: [ NO_ERRORS_SCHEMA ]
})
export class AppModule { }
