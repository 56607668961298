import { Component, OnInit, Inject } from '@angular/core';
import { Service } from '../services/service';
import { Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

declare var $: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  User: any = {};
  connectForm = this.fb.group({
    type: [1, Validators.required],
    login: ['', Validators.required],
    pass: ['', Validators.required]
  });

  // loginUser: FormControl = new FormControl(null, Validators.required);

  // tslint:disable-next-line:max-line-length
  constructor(private manager: Service, private router: Router, private fb: FormBuilder) { }

  redirect(data?) {
    this.router.navigate(['admin']);
  }

  login() {
    this.User = this.connectForm.value;
    this.manager.user('login', this.User)
      .subscribe((data) => {
        if (data.state) {
          this.manager.currentUser = data;
          localStorage.setItem('ssUser', JSON.stringify(data));
          this.redirect();
        } else {
          alert('Ce compte n\'existe pas! \n Veillez reéssayer!');
        }
      });
  }

  current() {
    const userData = JSON.parse(localStorage.getItem('ssUser'));
    this.manager.user('login', userData)
    .subscribe(
      (data: any) => {
        if (data.state) {
          this.manager.currentUser = data;
          this.router.navigate(['admin']);
        }
      },
      (error) => {
        $('#error').modal('show');
      }
    );
  }

  ngOnInit() {
    const userData = localStorage.getItem('ssUser') ? JSON.parse(localStorage.getItem('ssUser')) : {state: false};
    if (userData.state) {
      this.manager.currentUser = userData;
      this.router.navigate(['admin']);
    }
  }

}
