<div class="container pb-3">
  <!-- <h3 class="h1 w-100 text-center mt-5 pt-5"><span class="text-danger animated fadeInLeft">SKULL</span><span class="text-danger animated fadeInRight">SPACE</span></h3> -->
  <div class="card w-50 mx-auto mt-5 animated fadeInDown">
    <div class="card-header py-1 text-white bg-ss  d-flex justify-content-between">
      <span class="modal-title w-100 font-weight-bold h6">CONNEXION</span> 
      <div class="h6">
        <i class="fa fa-user-circle"></i>
      </div>
    </div>
    <form [formGroup]="connectForm" (ngSubmit)="login()">
      <div class="card-body mx-3">
        <div class="form-group">
          <label>Qui êtes-vous?</label>
          <select formControlName="type" class="form-control">
            <option [value]="1"><i class="fa fa-sign-in"></i> Personnel</option>
            <option [value]="2" selected><i class="fa fa-sign-in"></i> Elève</option>
            <option [value]="3"><i class="fa fa-sign-in"></i> Parent</option>
          </select>
        </div>
        <div class="form-group">
          <label>Nom d'utilisateur </label>
          <input formControlName="login" type="text" class="form-control" placeholder="Nom d'utilisateur">
        </div>
        <div class="form-group">
          <label>Mot de passe</label>
          <input formControlName="pass" type="password" class="form-control" placeholder="Mot de passe">
        </div>
      </div>
      <div class="card-footer">
        <button type="submit" class="btn bg-sl btn-sm pull-right" [disabled]="!connectForm.valid">Connexion</button>
      </div>
    </form>
  </div>
</div>