export const AppModules = {
    FraisMedicaux: {
        print: true,
        inscription: true,
        roles: [],
        actif:true
    },
    Reduction: {
        actif: true,
        roles: []
    },
    Transport: {
        actif: true,
        roles: []
    },
    Matieres: {
        actif: true,
        roles: []
    },
    Messagerie: {
        actif: true,
        roles: [8]
    },
    Parents: {
        actif: true,
        roles: [8]
    },
    Bulletins: {
        actif: true,
        roles: [8]
    },
    Personnel: {
        actif: true,
        roles: [6,7,8]
    },
    Configuration: {
        actif: true,
        roles: [8]
    },
    Audit:{
        actif: true,
        roles:[8]
    }
    ,
    Eleves: {
        actif: true,
        roles:[5,6,7,8],
        Inscrits: {
            actif: true,
            FraisMedicaux: {
                actif: true,
                print: true
            }
        },
        Liste: {
            actif: true
        }
    }
    ,
    Scolarite: {
        actif: true,
        roles: [4,5,6,7,8],
        Inscription: {
            actif: true,
            roles: [4,5,6,7,8],
            montantInscription: true,
            anciensEleves:{
                actif: true,
                roles: []
            },
            nouveauxEleves:{
                actif: true,
                roles: []
            },
            matricule: {
                actif:true,
                roles: [8]
            },
            FraisMedicaux: {
                actif: true,
                print: true,
                roles: [5,6,7,8]
            },
            Reductions: {
                actif: true,
                print: true,
                roles: [5,6,7,8]
            },
            
            
        },
        Versements: {
            actif: true,
            roles: [6,7,8],
            delete:{
                actif: true,
                roles: [8]
            }
        },
        FraisScolaire: {
            actif: true,
            roles: [5,6,7,8],
            print: {

            },
            Versements: {
                actif: true,
                roles: [6,7,8],
                delete:{
                    actif: true,
                    roles: [8]
                }
            },
            Eleves: {
                actif: true,
                update: true,
                roles:[5,6,7,8],

            },
            Transport:{
                actif: true
            },

            Reductions:{
                actif: true
            },
            FraisMedicaux: {
                actif: true,
                print: true,
                roles: [5,6,7,8]
            },
        },
        EtatTranche: {
            actif: true,
            roles: [5,6,7,8],
            print: {
                roles: [],
                actif: true
            },
            tri: {

            }
        }
    }


}
