import { Injectable, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment as ACTIVE_MODE } from '../../environments/environment';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
declare var $: any;

@Injectable({
  providedIn: 'root',
})
export class Service {
  private baseURI: string = ACTIVE_MODE.baseURI;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  // private mUrl: string = "http://allomonassurance.sildy.pro/server/zphp/mailerh.php";
  public hideForm = false;
  public selectEleveInscrit = '*';
  public currentUser: any = {};
  public Classes: any = [];
  public Cycles: any = [];
  public Sections: any = [];
  public Eleves: any = [];
  public Reductions: any = [];
  public Personnels: any = [];
  public ElevesInscrits: any = [];
  public Annees: any = [];
  public TotalElevesInscrits: any = [];
  public TypePers: any = [];
  public Fonctions: any = [];
  public Transport: any = [];
  public EtatGlobal: any = [];
  public EtatGlobalTab = new MatTableDataSource();
  public Bus: any = [];
  public Dest: any = [];
  public Trans: any = [];
  public Parents: any = [];
  public Matieres: any = [];
  public MatieresClasse: any = [];
  public Periode: any = [];
  public Examen: any = [];
  public MatieresDispense: any = [];
  public startDate = new Date('08-12-2024 20:00:00');
  public endDate = new Date('08-14-2025 00:00:00');
  public errDate = false;
  public licencesComplete = false;
  public ecole: any = {};
  categorie: any = [];

  constructor(private http: HttpClient, private route: Router) {
    if (new Date().getTime() < this.startDate.getTime()) {
      this.errDate = true;
      if (new Date().getTime() >= this.endDate.getTime()) {
        this.licencesComplete = true;
        // this.route.navigate(['license']);
      }
      this.route.navigate(['license']);
    } else {
      if (new Date().getTime() >= this.endDate.getTime()) {
        this.licencesComplete = true;
        this.route.navigate(['license']);
      }
    }

    this.currentUser = localStorage.getItem('ssUser')
      ? JSON.parse(localStorage.getItem('ssUser'))
      : { state: false };

    // this.currentUser = localStorage.getItem('ssUser') ? JSON.parse(localStorage.getItem('ssUser')) : { state: false };

    // console.log(new Date(1566241200000));
    // console.log(new Date('08-19-2019 20:00:00').getTime());
    // console.log(new Date('08-19-2019 20:00:00').getTime() - new Date().getTime());
  }

  checkRoles(a, b) {
    return a.some((e) => b.includes(e));
  }
  // FUNCTION  GET,

  get(
    nom?: any,
    conditions?: object,
    controller?: any,
    categorie?: any,
    orderBy?: any,
    limits?: any
  ) {
    if (!orderBy) {
      orderBy = {};
    }
    if (!conditions) {
      conditions = {};
    }
    if (!limits) {
      limits = {};
    }

    const db = {
      name: nom,
      type: 'get',
      categorie,
      controller: controller ? controller : 'general',
    };

    // const db = {type: 'get', controller: route};

    return this.http.post<any>(this.baseURI, {
      entity: db,
      conditions,
      orderBy,
      limits,
    });
  }

  // FUNCTION PUT

  put(nom: any, objet: object, controller: any = 'general') {
    const url: any = this.baseURI;
    const db = { name: nom, type: 'put', controller };
    // console.log(objet);
    // return objet ;
    return this.http.post(url, { entity: db, conditions: objet });
  }

  message(data: object, type: any) {
    const url: any = this.baseURI;
    // console.log(objet);
    // return objet ;
    return this.http.post(url, {
      entity: { type, controller: 'message' },
      conditions: data,
    });
  }

  delete(nom: any, objet?: object, controller?: any) {
    const url: any = this.baseURI;
    const db = {
      name: nom,
      type: 'del',
      controller: controller ? controller : 'general',
    };
    // console.log(objet);
    // return objet ;
    return this.http.post(url, { entity: db, conditions: objet });
  }

  // Gestion user

  user(action, data) {
    const db = { controller: 'user' };
    return this.http.post<any>(this.baseURI, { entity: db, action, data });
  }

  select(route: any, conditions?: object, orderBy?: any, limits?: any) {
    if (!orderBy) {
      orderBy = {};
    }
    if (!route) {
      // route='mail';
    }
    if (!conditions) {
      conditions = {};
    }
    if (!limits) {
      limits = {};
    }

    const db = { type: 'get', controller: route };

    return this.http.post(this.baseURI, {
      entity: db,
      conditions,
      orderBy,
      limits,
    });
  }

  getAllMatiere() {
    this.MatieresClasse = [];
    this.get('', {}, 'matiere').subscribe(
      (matiere) => {
        this.MatieresClasse = matiere;
      },
      (error) => {
        window.alert("Il se trouve qu'il y'a une erreur avec le serveur.");
        console.log(error);
      }
    );
  }

  getReduction(data?: object) {
    this.Reductions = [];
    this.get('', data, 'reduction').subscribe(
      (reduction) => {
        this.Reductions = reduction;
      },
      (error) => {
        window.alert(
          "Il se trouve qu'il y'a une erreur avec le serveur." + error
        );
      }
    );
  }

  getAllPeriode() {
    this.Periode = [];
    this.get('Periode').subscribe(
      (periode) => {
        this.Periode = periode;
      },
      (error) => {
        window.alert("Il se trouve qu'il y'a une erreur avec le serveur.");
        console.log(error);
      }
    );
  }

  getAllExamen(data?: any) {
    this.Examen = [];
    this.get('Examen', data).subscribe(
      (examen) => {
        this.Examen = examen;
      },
      (error) => {
        window.alert("Il se trouve qu'il y'a une erreur avec le serveur.");
        console.log(error);
      }
    );
  }

  // Fonction send mail

  // sendMail(objet) {

  //     return this.http.post(this.mUrl, { conditions: objet });
  // }

  /* Usual functions */

  getEtatGlobal(data?: any) {
    this.EtatGlobal = [];
    // this.getAllInscrit(data);
    this.get('', data, 'inspension').subscribe((params: any) => {
      this.EtatGlobal = params;
      if (this.selectEleveInscrit === 'solde') {
        this.EtatGlobal = this.EtatGlobal.filter((item) => {
          return item.etat !== true;
        });
      } else if (this.selectEleveInscrit === 'nonSolde') {
        this.EtatGlobal = this.EtatGlobal.filter((item) => {
          return item.etat !== false;
        });
      }
      this.EtatGlobalTab.data = this.EtatGlobal;
      this.EtatGlobalTab.paginator = this.paginator;
    });
    // this.getAllInscrit();
  }

  getAllClasses() {
    this.Classes = [];
    this.get('Classes', { actif: 1 }).subscribe(
      (Classes) => {
        this.Classes = Classes;
        $('table.filter').DataTable();
      },
      (error) => {
        window.alert("Il se trouve qu'il y'a une erreure avec le serveur.");
        console.log(error);
      }
    );
  }

  getMatiere(data?: any) {
    // console.log(data);
    this.Matieres = [];
    this.get('', data, 'dispense', 'matiere').subscribe(
      (matiere: any) => {
        this.Matieres = matiere;
        $('table.filter').DataTable();
        // console.log(matiere);
      },
      (error) => {
        window.alert("Il se trouve qu'il y'a une erreur avec le serveur.");
        console.log(error);
      }
    );
  }

  getMatiereClasse(data?: any) {
    // console.log(data);
    this.MatieresDispense = [];
    this.get('', data, 'dispense', 'prof').subscribe(
      (matiere: any) => {
        this.MatieresDispense = matiere;
        $('table.filter').DataTable();
        // console.log(matiere);
      },
      (error) => {
        window.alert("Il se trouve qu'il y'a une erreur avec le serveur.");
        console.log(error);
      }
    );
  }

  getAllPers() {
    this.Personnels = [];
    this.get('Personnel').subscribe(
      (Pers) => {
        this.Personnels = Pers;
        $('table.filter').DataTable();
        // console.log(this.Personnels);
      },
      (error) => {
        window.alert("Il se trouve qu'il y'a une erreur avec le serveur.");
        console.log(error);
      }
    );
  }

  getAllCycles() {
    this.Cycles = [];
    this.get('Cycles').subscribe(
      (Cycles) => {
        this.Cycles = Cycles;
        $('table.filter').DataTable();
      },
      (error) => {
        window.alert("Il se trouve qu'il y'a une erreur avec le serveur.");
        console.log(error);
      }
    );
  }

  getAllSections() {
    this.Sections = [];
    this.get('Sections').subscribe(
      (Sections) => {
        this.Sections = Sections;
        $('table.filter').DataTable();
      },
      (error) => {
        window.alert("Il se trouve qu'il y'a une erreur avec le serveur.");
        console.log(error);
      }
    );
  }

  getAllEleves() {
    this.Eleves = [];
    this.get('Eleves', {}, '', '', { nom: 'ASC' }).subscribe(
      (Eleves) => {
        this.Eleves = Eleves;
        $('table.filter').DataTable();
        //   console.log(this.Eleves);
      },
      (error) => {
        window.alert("Il se trouve qu'il y'a une erreur avec le serveur.");
        console.log(error);
      }
    );
  }

  getAllParents() {
    this.Parents = [];
    this.get('Parents', {}, 'parent', '', {
      civilite: 'ASC',
      nom: 'ASC',
      prenom: 'ASC',
    }).subscribe(
      (Parents) => {
        this.Parents = Parents;
        $('table.filter').DataTable();
      },
      (error) => {
        window.alert("Il se trouve qu'il y'a une erreur avec le serveur.");
        console.log(error);
      }
    );
  }

  getAllInscrit(eleve?: any) {
    this.ElevesInscrits = [];
    this.TotalElevesInscrits = [];
    this.get('', eleve, 'eleves').subscribe(
      (Eleves) => {
        if (eleve) {
          this.ElevesInscrits = Eleves;
        } else {
          this.ElevesInscrits = Eleves;
          this.TotalElevesInscrits = Eleves;
        }
        $('table.filter').DataTable();
      },
      (error) => {
        window.alert("Il se trouve qu'il y'a une erreur avec le serveur.");
        console.log(error);
      }
    );
  }

  getAllAnnees() {
    this.Annees = [];
    this.get('Annees').subscribe(
      (Annees) => {
        this.Annees = Annees;
        $('table.filter').DataTable();
      },
      (error) => {
        window.alert("Il se trouve qu'il y'a une erreur avec le serveur.");
        console.log(error);
      }
    );
  }

  // getTransport() {
  //     this.get('', {}, 'transport', 'transport')
  //         .subscribe(
  //             (Transport) => {
  //                 this.Transport = Transport;
  //             },
  //             (error) => {
  //                 window.alert('Il se trouve qu\'il y\'a une erreur avec le serveur.');
  //                 console.log(error);
  //             }
  //         );
  // }

  getAllTypePers() {
    this.TypePers = [];
    this.get('TypePersonnel').subscribe(
      (TypePers) => {
        this.TypePers = TypePers;
        $('table.filter').DataTable();
      },
      (error) => {
        window.alert("Il se trouve qu'il y'a une erreur avec le serveur.");
        console.log(error);
      }
    );
  }

  getAllFonctions() {
    this.Fonctions = [];
    this.get('Fonctions').subscribe(
      (Fonctions) => {
        this.Fonctions = Fonctions;
        $('table.filter').DataTable();
      },
      (error) => {
        window.alert("Il se trouve qu'il y'a une erreur avec le serveur.");
        console.log(error);
      }
    );
  }

  getAllBus() {
    this.Bus = [];
    this.get('Bus').subscribe(
      (Bus) => {
        this.Bus = Bus;
        $('table.filter').DataTable();
      },
      (error) => {
        window.alert("Il se trouve qu'il y'a une erreur avec le serveur.");
        console.log(error);
      }
    );
  }

  getAllDest() {
    this.Dest = [];
    this.get('Destinations').subscribe(
      (Dest) => {
        this.Dest = Dest;
        $('table.filter').DataTable();
      },
      (error) => {
        window.alert("Il se trouve qu'il y'a une erreur avec le serveur.");
        console.log(error);
      }
    );
  }

  getAllCategorie() {
    this.categorie = [];
    this.get('Categories').subscribe(
      (data) => {
        this.categorie = data;
        $('table.filter').DataTable();
      },
      (error) => {
        window.alert("Il se trouve qu'il y'a une erreur avec le serveur.");
        console.log(error);
      }
    );
  }

  getAllTrans() {
    this.Trans = [];
    this.get('', {}, 'transport', 'transport').subscribe(
      (Trans) => {
        this.Trans = Trans;
        $('table.filter').DataTable();
      },
      (error) => {
        window.alert("Il se trouve qu'il y'a une erreur avec le serveur.");
        console.log(error);
      }
    );
  }

  getAllFraisTrans(data?) {
    this.Transport = [];
    this.get('', data, 'transport', 'all').subscribe(
      (Transport) => {
        this.Transport = Transport;
        $('table.filter').DataTable();
      },
      (error) => {
        window.alert("Il se trouve qu'il y'a une erreur avec le serveur.");
        console.log(error);
      }
    );
  }
}
