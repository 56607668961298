import { Injectable } from '@angular/core';

import pdfMake from 'pdfmake/build/pdfmake.js';
import pdfFonts from 'pdfmake/build/vfs_fonts.js';
import { Service } from './service';
import {  environment as ACTIVE_MODE } from '../../environments/environment';
import { AppModules } from '../shared/data';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Injectable({
  providedIn: 'root'
})
export class UsualsFunctionsService {

  Classes: any = {};
  Cycles: any = {};
  Sections: any = {};
  Annees: any = {};
  Eleves: any = {};
  docDefinition: any = {};
  img = ACTIVE_MODE.img;

  constructor(private manager: Service) { }

  toDataURL(url?, callback?) {
    url = this.manager.ecole.logo ? this.manager.ecole.logo : url;
    const xhr = new XMLHttpRequest();
    xhr.onload = () => {
      const reader = new FileReader();
      reader.onloadend = () => {
        callback(reader.result);
      };
      reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
  }


  imprimerFactureAudit(donnees) {
    let content = [];
    this.toDataURL(this.img, (image) => {
      let addCont=1;
      donnees.forEach(element => {
        let param = JSON.parse(element.donnees);
        param.numero = element.numero;
        content.push(
          {
            columns: [
              {
                width: '40%',
                stack: [
                  { text: this.manager.ecole.nom_fr, alignment: 'center', bold: true, fontSize: 8, lineHeight: 2 },
                  { text: this.manager.ecole.libelle, alignment: 'center', bold: true, fontSize: 8, lineHeight: 2 },
                  { text: this.manager.ecole.nom_en, alignment: 'center', bold: true, fontSize: 7 },
  
                ],
              },
              {
                width: '25%',
                stack: [
                  {
                    // tslint:disable-next-line:max-line-length
                    image,
                    fit: [65, 65],
                    alignment: 'center'
                  }
                ]
  
              },
              {
                width: '35%',
                alignment: 'center',
                stack: [
                  { text: 'SERVICE DE LA SCOLARITE ', fontSize: 9, bold: true, lineHeight: 2 },
                  { text: 'REGISTRATION SERVICE ', fontSize: 8, bold: true, lineHeight: 2},
                  { text: 'Tél: ' + this.manager.ecole.tel1 + ' | Email: ' + this.manager.ecole.email + '', fontSize: 6, bold: true },
                ],
  
  
              },
  
  
            ]
          },
          { text: 'RECU / RECEIPT No ' + param.numero, alignment: 'center', bold: true, fontSize: 11 },
          { text: 'FRAIS DE SCOLARITE / TUITION FEES', alignment: 'center', fontSize: 10 },
          { text: ' ', fontSize: 4, alignment: 'center' },
  
          {
            fontSize: 8,
            columns: [
              {
                width: '10%',
                bold: true,
                alignment: 'left',
                fontSize: 8,
                stack: [
  
                  { text: 'Matricule ', },
                  { text: 'Nom(s)' },
                  { text: 'Prenom(s) ' },
                  { text: 'Classe' },
  
                ]
  
              },
  
              {
                width: '30%',
                alignment: 'left',
                fontSize: 8,
                stack: [
  
                  { text: param.Eleve.matricule },
                  { text: param.Eleve.nom },
                  { text: (param.Eleve.prenom ? param.Eleve.prenom : '') },
                  { text: param.Eleve.libelle },
  
  
                ]
  
              },
              {
                width: '30%',
                alignment: 'left',
                stack: [
  
                  { text: 'Montant versé ', fontSize: 8, },
                  { text: param.verser + ' F CFA', bold: true, fontSize: 11 },
                  { text: (param.reduction !== 0) ? '(Avec une reduction de ' + param.reduction + ' F CFA) ' : ' ', fontSize: 8, bold: true },
                  { text: ' ', fontSize: 6 },
                  { text: ' ', fontSize: 6 },
                ]
              },
              {
                width: '13%',
                bold: true,
                alignment: 'left',
                fontSize: 8,
                stack: [
                  'Net à payer ',
                  'Déjà payer',
                  { text: 'Rester à payer ' },
                  AppModules.Scolarite.Inscription.FraisMedicaux.actif ? { text: 'Frais médicaux ', bold: false, }:'',
                ]
  
              },
              {
                width: '15%',
                alignment: 'left', fontSize: 8,
                stack: [
  
                  { text: param.totalAttendu + ' F CFA' },
                  { text: param.totalVerser + ' F CFA' },
                  { text: param.totalReste + ' F CFA' },
                  AppModules.Scolarite.Inscription.FraisMedicaux.actif ? { text: param.fraisMedicaux? '5 000 F CFA': '0 F CFA' }:'',
  
  
                ]
  
              },
  
            ]
          },
          { text: ' ', fontSize: 4, alignment: 'center' },
          {
            columns: [
              {
                stack: [
                  { text: 'DETAILS DES PAIEMENT DU COMPTE', bold: true, fontSize: 8 },
                  { text: ' ', fontSize: 4, alignment: 'center' },
                  {
                    fontSize: 7,
                    layout: 'lightHorizontalLines', // optional
                    table: {
                      // headers are automatically repeated if the table spans over multiple pages
                      // you can declare how many rows should be treated as headers
                      headerRows: 1,
                      widths: ['auto', 'auto', 'auto', 'auto'],
  
                      body: [
                        // tslint:disable-next-line:max-line-length
                        [{ text: 'Eléments', bold: true }, { text: 'Attendu', bold: true }, { text: 'Perçu', bold: true }, { text: 'Reste', bold: true }],
                        [{ text: 'Inscription', bold: true }, param.inscription.attendu, param.inscription.verser, param.inscription.reste],
                        [{ text: '1ère tranche', bold: true }, param.tranche1.attendu, param.tranche1.verser, param.tranche1.reste],
                        [{ text: '2ème tranche', bold: true }, param.tranche2.attendu, param.tranche2.verser, param.tranche2.reste],
                        [{ text: '3ème tranche', bold: true }, param.tranche3.attendu, param.tranche3.verser, param.tranche3.reste],
                      ]
                    }
                  }
                ],
  
  
                width: '50%',
  
              },
              { width: '20%', text: ' ' },
              {
                width: '30%',
                stack: [
                  {
                    fontSize: 8,
                    alignment: 'left',
                    columns: [
                      {
                        stack: [
  
  
                          { text: 'Saisi par' },
                          { text: 'Le ' }
  
                        ],
                        width: '23%',
                        bold: true,
                      },
                      {
                        stack: [
  
                          { text: ' : ' },
                          { text: ' : ' }
  
                        ],
                        width: '2%',
                        bold: true,
  
                      },
                      {
                        stack: [
  
                          { text: param.caissier },
                          { text: ' ' + param.date }
  
                        ],
                        width: '75%',
                      },
                    ],
  
                  },
                  { text: ' ', fontSize: 4, alignment: 'center' },
                  { text: 'SIGNATURE ET CACHET', alignment: 'center' }
                ],
  
  
                alignment: 'center',
                fontSize: 8
              },
  
            ]
          },
          {text: ' ', fontSize: 6 },
          // tslint:disable-next-line:max-line-length
          { alignment: 'center', text: 'NB : Les droits d\'inscription et les fais de scolarité ne sont ni remboursables ni substituables.', fontSize: 6 },
          {text: ' ', fontSize: 5 },
          {text: '----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------', fontSize: 5 },
          
          
          // tslint:disable-next-line:max-line-length
            
        )
      });
      this.docDefinition.content = content;
      pdfMake.createPdf(this.docDefinition).open();
    });
  }


  imprimerFacture(param) {
    this.toDataURL(this.img, (image) => {
      this.docDefinition.content =
      [
        {
          columns: [
            {
              width: '40%',
              stack: [
                { text: this.manager.ecole.nom_fr, alignment: 'center', bold: true, fontSize: 8, lineHeight: 2 },
                { text: this.manager.ecole.libelle, alignment: 'center', bold: true, fontSize: 8, lineHeight: 2 },
                { text: this.manager.ecole.nom_en, alignment: 'center', bold: true, fontSize: 7 },

              ],
            },
            {
              width: '25%',
              stack: [
                {
                  // tslint:disable-next-line:max-line-length
                  image,
                  fit: [65, 65],
                  alignment: 'center'
                }
              ]

            },
            {
              width: '35%',
              alignment: 'center',
              stack: [
                { text: 'SERVICE DE LA SCOLARITE ', fontSize: 9, bold: true, lineHeight: 2 },
                { text: 'REGISTRATION SERVICE ', fontSize: 8, bold: true, lineHeight: 2},
                { text: 'Tél: ' + this.manager.ecole.tel1 + ' | Email: ' + this.manager.ecole.email + '', fontSize: 6, bold: true },
              ],


            },


          ]
        },
        { text: 'RECU / RECEIPT No ' + param.numero, alignment: 'center', bold: true, fontSize: 11 },
        { text: 'FRAIS DE SCOLARITE / TUITION FEES', alignment: 'center', fontSize: 10 },
        { text: ' ', fontSize: 4, alignment: 'center' },

        {
          fontSize: 8,
          columns: [
            {
              width: '10%',
              bold: true,
              alignment: 'left',
              fontSize: 8,
              stack: [

                { text: 'Matricule ', },
                { text: 'Nom(s)' },
                { text: 'Prenom(s) ' },
                { text: 'Classe' },

              ]

            },

            {
              width: '30%',
              alignment: 'left',
              fontSize: 8,
              stack: [

                { text: param.Eleve.matricule },
                { text: param.Eleve.nom },
                { text: (param.Eleve.prenom ? param.Eleve.prenom : '') },
                { text: param.Eleve.libelle },


              ]

            },
            {
              width: '30%',
              alignment: 'left',
              stack: [

                { text: 'Montant versé ', fontSize: 8, },
                { text: param.verser + ' F CFA', bold: true, fontSize: 11 },
                { text: (param.reduction !== 0) ? '(Avec une reduction de ' + param.reduction + ' F CFA) ' : ' ', fontSize: 8, bold: true },
                { text: ' ', fontSize: 6 },
                { text: ' ', fontSize: 6 },
              ]
            },
            {
              width: '13%',
              bold: true,
              alignment: 'left',
              fontSize: 8,
              stack: [
                'Net à payer ',
                'Déjà payer',
                { text: 'Rester à payer ' },
                AppModules.Scolarite.Inscription.FraisMedicaux.actif ? { text: 'Frais médicaux ', bold: false, }:'',
              ]

            },
            {
              width: '15%',
              alignment: 'left', fontSize: 8,
              stack: [

                { text: param.totalAttendu + ' F CFA' },
                { text: param.totalVerser + ' F CFA' },
                { text: param.totalReste + ' F CFA' },
                AppModules.Scolarite.Inscription.FraisMedicaux.actif ? { text: param.fraisMedicaux? '5 000 F CFA': '0 F CFA' }:'',


              ]

            },

          ]
        },
        { text: ' ', fontSize: 4, alignment: 'center' },
        {
          columns: [
            {
              stack: [
                { text: 'DETAILS DES PAIEMENT DU COMPTE', bold: true, fontSize: 8 },
                { text: ' ', fontSize: 4, alignment: 'center' },
                {
                  fontSize: 7,
                  layout: 'lightHorizontalLines', // optional
                  table: {
                    // headers are automatically repeated if the table spans over multiple pages
                    // you can declare how many rows should be treated as headers
                    headerRows: 1,
                    widths: ['auto', 'auto', 'auto', 'auto'],

                    body: [
                      // tslint:disable-next-line:max-line-length
                      [{ text: 'Eléments', bold: true }, { text: 'Attendu', bold: true }, { text: 'Perçu', bold: true }, { text: 'Reste', bold: true }],
                      [{ text: 'Inscription', bold: true }, param.inscription.attendu, param.inscription.verser, param.inscription.reste],
                      [{ text: '1ère tranche', bold: true }, param.tranche1.attendu, param.tranche1.verser, param.tranche1.reste],
                      [{ text: '2ème tranche', bold: true }, param.tranche2.attendu, param.tranche2.verser, param.tranche2.reste],
                      [{ text: '3ème tranche', bold: true }, param.tranche3.attendu, param.tranche3.verser, param.tranche3.reste],
                    ]
                  }
                }
              ],


              width: '50%',

            },
            { width: '20%', text: ' ' },
            {
              width: '30%',
              stack: [
                {
                  fontSize: 8,
                  alignment: 'left',
                  columns: [
                    {
                      stack: [


                        { text: 'Saisi par' },
                        { text: 'Le ' }

                      ],
                      width: '23%',
                      bold: true,
                    },
                    {
                      stack: [

                        { text: ' : ' },
                        { text: ' : ' }

                      ],
                      width: '2%',
                      bold: true,

                    },
                    {
                      stack: [

                        { text: param.caissier },
                        { text: ' ' + param.date }

                      ],
                      width: '75%',
                    },
                  ],

                },
                { text: ' ', fontSize: 4, alignment: 'center' },
                { text: 'SIGNATURE ET CACHET', alignment: 'center' }
              ],


              alignment: 'center',
              fontSize: 8
            },

          ]
        },
        {text: ' ', fontSize: 6 },
        // tslint:disable-next-line:max-line-length
        { alignment: 'center', text: 'NB : Les droits d\'inscription et les fais de scolarité ne sont ni remboursables ni substituables.', fontSize: 6 },
        {text: ' ', fontSize: 6 },
        // tslint:disable-next-line:max-line-length
        '-----------------------------------------------------------------------------------------------------------------------------------------------------------',
        ' ',
        {
          columns: [
            {
              width: '40%',
              stack: [

                { text: this.manager.ecole.nom_fr, alignment: 'center', bold: true, fontSize: 8, lineHeight: 2 },
                { text: this.manager.ecole.libelle, alignment: 'center', bold: true, fontSize: 8, lineHeight: 2 },
                { text: this.manager.ecole.nom_en, alignment: 'center', bold: true, fontSize: 7 },

              ],


            },
            {
              width: '25%',
              stack: [
                {
                  // tslint:disable-next-line:max-line-length
                  image,
                  fit: [65, 65],
                  alignment: 'center'
                }
              ]

            },
            {
              width: '35%',
              alignment: 'center',
              stack: [

                { text: 'SERVICE DE LA SCOLARITE ', fontSize: 9, lineHeight: 2 },
                { text: 'REGISTRATION SERVICE ', fontSize: 8, lineHeight: 2 },
                { text: 'Tél:' + this.manager.ecole.tel1 + ' | Email: ' + this.manager.ecole.email + '', fontSize: 6 },
              ],


            },


          ]
        },
        { text: 'RECU / RECEIPT No ' + param.numero, alignment: 'center', bold: true, fontSize: 11 },
        { text: 'FRAIS DE SCOLARITE / TUITION FEES', alignment: 'center', fontSize: 10 },
        { text: ' ', fontSize: 4, alignment: 'center' },

        {
          fontSize: 8,
          columns: [
            {
              width: '10%',
              bold: true,
              alignment: 'left',
              fontSize: 8,
              stack: [

                { text: 'Matricule ', },
                { text: 'Nom(s)' },
                { text: 'Prenom(s) ' },
                { text: 'Classe' },

              ]

            },

            {
              width: '30%',
              alignment: 'left',
              fontSize: 8,
              stack: [

                { text: param.Eleve.matricule },
                { text: param.Eleve.nom },
                { text: (param.Eleve.prenom ? param.Eleve.prenom : '') },
                { text: param.Eleve.libelle },


              ]

            },
            {
              width: '30%',
              alignment: 'left',
              stack: [

                { text: 'Montant versé ', fontSize: 8, },
                { text: param.verser + ' F CFA', bold: true, fontSize: 11 },
                { text: (param.reduction !== 0) ? '(Avec une reduction de ' + param.reduction + ' F CFA) ' : ' ', fontSize: 8, bold: true },
                { text: ' ', fontSize: 6 },

              ]
            },
            {
              width: '13%',
              bold: true,
              alignment: 'left',
              fontSize: 8,
              stack: [
                'Net à payer ',
                'Déjà payer',
                { text: 'Rester à payer ' },
                AppModules.Scolarite.Inscription.FraisMedicaux.actif ? { text: 'Frais médicaux ', bold: false, }:'',
              ]

            },
            {
              width: '15%',
              alignment: 'left', fontSize: 8,
              stack: [

                { text: param.totalAttendu + ' F CFA' },
                { text: param.totalVerser + ' F CFA' },
                { text: param.totalReste + ' F CFA' },
                AppModules.Scolarite.Inscription.FraisMedicaux.actif ? { text: param.fraisMedicaux? '5 000 F CFA': '0 F CFA' }:'',


              ]

            },

          ]
        },
        { text: ' ', fontSize: 4, alignment: 'center' },
        {
          columns: [
            {
              stack: [
                { text: 'DETAILS DES PAIEMENT DU COMPTE', bold: true, fontSize: 8 },
                { text: ' ', fontSize: 4, alignment: 'center' },
                {
                  fontSize: 7,
                  layout: 'lightHorizontalLines', // optional
                  table: {
                    // headers are automatically repeated if the table spans over multiple pages
                    // you can declare how many rows should be treated as headers
                    headerRows: 1,
                    widths: ['auto', 'auto', 'auto', 'auto'],

                    body: [
                      // tslint:disable-next-line:max-line-length
                      [{ text: 'Eléments', bold: true }, { text: 'Attendu', bold: true }, { text: 'Perçu', bold: true }, { text: 'Reste', bold: true }],
                      [{ text: 'Inscription', bold: true }, param.inscription.attendu, param.inscription.verser, param.inscription.reste],
                      [{ text: '1ère tranche', bold: true }, param.tranche1.attendu, param.tranche1.verser, param.tranche1.reste],
                      [{ text: '2ème tranche', bold: true }, param.tranche2.attendu, param.tranche2.verser, param.tranche2.reste],
                      [{ text: '3ème tranche', bold: true }, param.tranche3.attendu, param.tranche3.verser, param.tranche3.reste],
                    ]
                  }
                }
              ],


              width: '50%',

            },
            { width: '20%', text: ' ' },
            {
              width: '30%',
              stack: [
                {
                  fontSize: 8,
                  alignment: 'left',
                  columns: [
                    {
                      stack: [


                        { text: 'Saisi par' },
                        { text: 'Le ' }

                      ],
                      width: '23%',
                      bold: true,
                    },
                    {
                      stack: [

                        { text: ' : ' },
                        { text: ' : ' }

                      ],
                      width: '2%',
                      bold: true,

                    },
                    {
                      stack: [

                        { text: param.caissier },
                        { text: ' ' + param.date }

                      ],
                      width: '75%',
                    },
                  ],

                },
                { text: ' ', fontSize: 4, alignment: 'center' },
                { text: 'SIGNATURE ET CACHET', alignment: 'center' }
              ],


              alignment: 'center',
              fontSize: 8
            },

          ]
        },
        {text: ' ', fontSize: 6 },
        // tslint:disable-next-line:max-line-length
        { alignment: 'center', text: 'NB : Les droits d\'inscription et les fais de scolarité ne sont ni remboursables ni substituables.', fontSize: 6 },
        {text: ' ', fontSize: 4 },
        // tslint:disable-next-line:max-line-length
        '-----------------------------------------------------------------------------------------------------------------------------------------------------------',
        {text: ' ', fontSize: 4 },
        {
          columns: [
            {
              width: '40%',
              stack: [

                { text: this.manager.ecole.nom_fr, alignment: 'center', bold: true, fontSize: 8, lineHeight: 2 },
                { text: this.manager.ecole.libelle, alignment: 'center', bold: true, fontSize: 8, lineHeight: 2 },
                { text: this.manager.ecole.nom_en, alignment: 'center', bold: true, fontSize: 7 },

              ],


            },
            {
              width: '25%',
              stack: [
                {
                  // tslint:disable-next-line:max-line-length
                  image,
                  fit: [65, 65],
                  alignment: 'center'
                }
              ]

            },
            {
              width: '35%',
              alignment: 'center',
              stack: [

                { text: 'SERVICE DE LA SCOLARITE ', fontSize: 9, lineHeight: 2 },
                { text: 'REGISTRATION SERVICE ', fontSize: 8, lineHeight: 2 },
                { text: 'Tél:' + this.manager.ecole.tel1 + ' | Email: ' + this.manager.ecole.email + '', fontSize: 6, bold: true },
              ],


            },


          ]
        },
        { text: 'RECU / RECEIPT No ' + param.numero, alignment: 'center', bold: true, fontSize: 11 },
        { text: 'FRAIS DE SCOLARITE / TUITION FEES', alignment: 'center', fontSize: 10 },
        { text: ' ', fontSize: 4, alignment: 'center' },

        {
          fontSize: 8,
          columns: [
            {
              width: '10%',
              bold: true,
              alignment: 'left',
              fontSize: 8,
              stack: [

                { text: 'Matricule ', },
                { text: 'Nom(s)' },
                { text: 'Prenom(s) ' },
                { text: 'Classe' },

              ]

            },

            {
              width: '30%',
              alignment: 'left',
              fontSize: 8,
              stack: [

                { text: param.Eleve.matricule },
                { text: param.Eleve.nom },
                { text: (param.Eleve.prenom ? param.Eleve.prenom : '') },
                { text: param.Eleve.libelle },


              ]

            },
            {
              width: '30%',
              alignment: 'left',
              stack: [

                { text: 'Montant versé ', fontSize: 8, },
                { text: param.verser + ' F CFA', bold: true, fontSize: 11 },
                { text: (param.reduction !== 0) ? '(Avec une reduction de ' + param.reduction + ' F CFA) ' : ' ', fontSize: 8, bold: true },
                { text: ' ', fontSize: 6 },

              ]
            },
            {
              width: '13%',
              bold: true,
              alignment: 'left',
              fontSize: 8,
              stack: [
                'Net à payer ',
                'Déjà payer',
                { text: 'Rester à payer ' },
                AppModules.Scolarite.Inscription.FraisMedicaux.actif ? { text: 'Frais médicaux ', bold: false, }:'',
              ]

            },
            {
              width: '15%',
              alignment: 'left', fontSize: 8,
              stack: [

                { text: param.totalAttendu + ' F CFA' },
                { text: param.totalVerser + ' F CFA' },
                { text: param.totalReste + ' F CFA' },
                AppModules.Scolarite.Inscription.FraisMedicaux.actif ? { text: param.fraisMedicaux? '5 000 F CFA': '0 F CFA' }:'',


              ]

            },

          ]
        },
        { text: ' ', fontSize: 4, alignment: 'center' },
        {
          columns: [
            {
              stack: [
                { text: 'DETAILS DES PAIEMENT DU COMPTE', bold: true, fontSize: 8 },
                { text: ' ', fontSize: 4, alignment: 'center' },
                {
                  fontSize: 7,
                  layout: 'lightHorizontalLines', // optional
                  table: {
                    // headers are automatically repeated if the table spans over multiple pages
                    // you can declare how many rows should be treated as headers
                    headerRows: 1,
                    widths: ['auto', 'auto', 'auto', 'auto'],

                    body: [
                      // tslint:disable-next-line:max-line-length
                      [{ text: 'Eléments', bold: true }, { text: 'Attendu', bold: true }, { text: 'Perçu', bold: true }, { text: 'Reste', bold: true }],
                      [{ text: 'Inscription', bold: true }, param.inscription.attendu, param.inscription.verser, param.inscription.reste],
                      [{ text: '1ère tranche', bold: true }, param.tranche1.attendu, param.tranche1.verser, param.tranche1.reste],
                      [{ text: '2ème tranche', bold: true }, param.tranche2.attendu, param.tranche2.verser, param.tranche2.reste],
                      [{ text: '3ème tranche', bold: true }, param.tranche3.attendu, param.tranche3.verser, param.tranche3.reste],
                    ]
                  }
                }
              ],


              width: '50%',

            },
            { width: '20%', text: ' ' },
            {
              width: '30%',
              stack: [
                {
                  fontSize: 8,
                  alignment: 'left',
                  columns: [
                    {
                      stack: [


                        { text: 'Saisi par' },
                        { text: 'Le ' }

                      ],
                      width: '23%',
                      bold: true,
                    },
                    {
                      stack: [

                        { text: ' : ' },
                        { text: ' : ' }

                      ],
                      width: '2%',
                      bold: true,

                    },
                    {
                      stack: [

                        { text: param.caissier },
                        { text: ' ' + param.date }

                      ],
                      width: '75%',
                    },
                  ],

                },
                { text: ' ', fontSize: 4, alignment: 'center' },
                { text: 'SIGNATURE ET CACHET', alignment: 'center' }
              ],


              alignment: 'center',
              fontSize: 8
            },


          ]
        },

        { alignment: 'right', text: 'NB : Les droits d\'inscription et les fais de scolarité ne sont ni remboursables ni substituables.', fontSize: 5 },

        // tslint:disable-next-line:max-line-length


      ];
      pdfMake.createPdf(this.docDefinition).open();
    });
  }

 


  printListe(liste: any, type: any, data?: any) {
    this.toDataURL(this.img, (image) => {
      let totalAttend = 0;
      let totalVerser = 0;
      let trans = 0;
      let reduc = 0;
      let totalpaye = 0;
      let total = 0;
      const totalSolde = 0;
      const body = [];
      let titre = '';
      let annee = '';
      let classe = '';
      let widths = [];
      let orientation = '';

      if (type === 0) {

        orientation = 'landscape';

        titre = 'LISTE ADMINISTRATIVE (Total: ' + liste.length + ')';
        annee = 'Année scolaire: ' + data.annee ;
        classe = 'Classe: ' + liste[0].libelle ;
        widths = ['auto', 'auto', '*', 'auto', 'auto'];
        // tslint:disable-next-line:max-line-length
        body.push([{ text: 'N°', bold: true, fontSize: 12, color: '#fff' }, { text: 'Matricule', bold: true, fontSize: 12, color: '#fff' }, { text: 'Nom & Prenom', bold: true, fontSize: 12, color: '#fff' }, { text: 'Date et lieu de naissance', bold: true, fontSize: 12, color: '#fff' }, { text: 'Statut', bold: true, fontSize: 12, color: '#fff' }]);

        // tslint:disable-next-line: only-arrow-functions
        liste.forEach( (value, key) => {
        // tslint:disable-next-line: max-line-length
          body.push([(key + 1), value.matricule, { text: value.nom + ' ' + (value.prenom ? value.prenom : ''), alignment: 'left' }, { text: (value.date_naiss ? value.date_naiss : '') + (value.lieu_naiss ? (' à ' + value.lieu_naiss) : ''), alignment: 'center' }, (value.ancien) ? 'Ancien' : 'Nouveau']);
        });
      
      }
      else if (type === 10) {
        let total =0;
        orientation = 'landscape';

        // tslint:disable-next-line:max-line-length
        body.push([{ text: 'N°', bold: true, fontSize: 12, color: '#fff' }, { text: 'Matricule', bold: true, fontSize: 12, color: '#fff' }, { text: 'Nom & Prénom', bold: true, fontSize: 12, color: '#fff' }, { text: 'Classe', bold: true, fontSize: 12, color: '#fff' }, { text: 'Frais Médicaux', bold: true, fontSize: 12, color: '#fff' }]);

        // tslint:disable-next-line: only-arrow-functions
        liste.forEach( (value, key) => {
          total = value.assurance_medicale ? total + 1: total
        // tslint:disable-next-line: max-line-length
          body.push([(key + 1), {text: value.matricule, alignment: 'left'}, { text: value.nom + ' ' + (value.prenom ? value.prenom : ''), alignment: 'left' }, { text: value.libelle, alignment: 'left'}, (value.assurance_medicale) ? '5 000' : '-']);
        });

        titre = 'Etat des frais médicaux (Total: ' + total+"/"+liste.length + ')';
        annee = 'Année scolaire: ' + data.annee ;
        classe = "Total versé: " + total * 5000  + "F CFA";
        widths = ['auto', 'auto', '*', 'auto', 'auto'];
        
      } 
      else if (type === 1) {

        orientation = 'portrait';

        titre = 'ETAT GENERALE DES FINANCES PAR ELEVE (Total: ' + liste.length + ')';
        orientation = 'landscape';
        widths = ['auto', 'auto', '*', '*', '*', '*', '*', '*', '*'];

        // tslint:disable-next-line:max-line-length
        body.push([{ text: 'N°', bold: true, fontSize: 12, color: '#fff' }, { text: 'Nom & Prenom', bold: true, fontSize: 12, color: '#fff' }, { text: 'Classe', bold: true, fontSize: 12, color: '#fff' }, { text: 'Année', bold: true, fontSize: 12, color: '#fff' }, { text: 'Montant Attendu', bold: true, fontSize: 12, color: '#fff' }, { text: 'Montant versé', bold: true, fontSize: 12, color: '#fff' }, { text: 'Solde', bold: true, fontSize: 12, color: '#fff' }, AppModules.Scolarite.FraisScolaire.Transport.actif?{ text: 'Transport', bold: true, fontSize: 12, color: '#fff' }:null, AppModules.Scolarite.FraisScolaire.Reductions.actif?{ text: 'Réduction', bold: true, fontSize: 12, color: '#fff' }:null]);

        liste.forEach( (value, key) => {
          totalAttend = totalAttend + value.totalAttendu;
          totalVerser = totalVerser + value.totalVerser;
          trans = trans + value.transport;
          reduc = reduc + value.reduction;
          // tslint:disable-next-line:max-line-length
          body.push([(key + 1), { text: value.Eleve.nom + ' ' + (value.Eleve.prenom ? value.Eleve.prenom : ''), alignment: 'left' }, value.Eleve.libelle, value.Eleve.annee, { text: value.totalAttendu + ' FCFA', alignment: 'right' }, { text: value.totalVerser + ' FCFA', alignment: 'right' }, { text: value.totalReste + ' FCFA', color: (value.totalReste > 0) ? 'red' : 'green', alignment: 'right' },  AppModules.Scolarite.FraisScolaire.Transport.actif?{ text: value.transport + ' FCFA', alignment: 'right' }:null,  AppModules.Scolarite.FraisScolaire.Reductions.actif?{ text: value.reduction + ' FCFA', alignment: 'right' }:null]);
        });
        // tslint:disable-next-line:max-line-length
        body.push([{ text: ' ', fillColor: '#000000' }, { text: ' ', bold: true, fillColor: '#000000', color: '#fff' }, { text: ' ', bold: true, fillColor: '#000000', color: '#fff' }, { text: 'Totaux', bold: true, fillColor: '#000000', color: '#fff' }, { text: totalAttend + ' FCFA', bold: true, color: 'blue', alignment: 'right' }, { text: totalVerser + ' FCFA', bold: true, color: 'blue', alignment: 'right' }, { text: totalAttend - totalVerser + ' FCFA', bold: true, color: 'blue', alignment: 'right' }, AppModules.Scolarite.FraisScolaire.Transport.actif?{ text: trans + ' FCFA', bold: true, color: 'blue', alignment: 'right' }:null, AppModules.Scolarite.FraisScolaire.Reductions.actif?{ text: reduc + ' FCFA', bold: true, color: 'blue', alignment: 'right' }:null]);
      } else if (type === 2) {

        orientation = 'landscape';

        titre = 'ELEVES ELIGIBLE A PRENDRE LE BUS (Total: ' + liste.length + ')';
        widths = ['auto', '*', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'];
        // tslint:disable-next-line:max-line-length
        body.push([{ text: 'N°', bold: true, fontSize: 12, color: '#fff' }, { text: 'Nom & Prenom', bold: true, fontSize: 12, color: '#fff' }, { text: 'Classe', bold: true, fontSize: 12, color: '#fff' }, { text: 'Destination', bold: true, fontSize: 12, color: '#fff' }, { text: 'Bus', bold: true, fontSize: 12, color: '#fff' }, { text: 'Montant payer', bold: true, fontSize: 12, color: '#fff' }, { text: 'Date debut', bold: true, fontSize: 12, color: '#fff' }, { text: 'Date fin', bold: true, fontSize: 12, color: '#fff' }]);

        liste.forEach( (value, key) => {
          // tslint:disable-next-line:max-line-length
          // tslint:disable-next-line:radix
          totalpaye = totalpaye + parseInt(value.montant);
          // tslint:disable-next-line:max-line-length
          body.push([(key + 1), { text: value.eleve_nom + ' ' + (value.eleve_prenom ? value.eleve_prenom : ''), alignment: 'left' }, value.classe, value.destination, value.code, { text: value.montant + ' FCFA', alignment: 'right' }, value.date_debut, value.date_fin ]);
        });

        // tslint:disable-next-line:max-line-length
        body.push([{ text: ' ', fillColor: '#000000' }, { text: ' ', bold: true, fillColor: '#000000', color: '#fff' }, { text: ' ', bold: true, fillColor: '#000000', color: '#fff' }, { text: '', bold: true, fillColor: '#000000', color: '#fff' }, { text: 'Totaux montant', bold: true, fillColor: '#000000', color: '#fff' }, { text: totalpaye + ' FCFA', bold: true, color: 'blue', alignment: 'right' }, { text: '', bold: true, fillColor: '#000000', color: '#fff' }, { text: '', bold: true, fillColor: '#000000', color: '#fff' }]);
      } else if (type === 3) {

        orientation = 'portrait';

        titre = 'VERSEMENTS EFFECTUER (Total: ' + liste.length + ')';
        widths = ['auto', '*', '*', '*'];
        // tslint:disable-next-line:max-line-length
        body.push([{ text: 'N°', bold: true, fontSize: 12, color: '#fff' }, { text: 'Caissier(e)', bold: true, fontSize: 12, color: '#fff' }, { text: 'Montant', bold: true, fontSize: 12, color: '#fff' }, { text: 'Date', bold: true, fontSize: 12, color: '#fff' }]);

        liste.forEach( (value, key) => {
          // tslint:disable-next-line:max-line-length
          // tslint:disable-next-line:radix
          total += parseInt(value.montant);
          // tslint:disable-next-line:max-line-length
          body.push([(key + 1), { text: value.nom + ' ' + ( value.prenom ?  value.prenom : ''), alignment: 'left' }, { text: value.montant + ' FCFA', alignment: 'right' }, value.date]);
        });

        // tslint:disable-next-line:max-line-length
        body.push([{ text: ' ', fillColor: '#000000' }, { text: 'Total', fillColor: '#000000', color: '#fff' }, { text: total + ' FCFA', bold: true, color: 'blue', alignment: 'right' }, { text: '', bold: true, fillColor: '#000000' }]);
      } else if (type === 4) {

        orientation = 'landscape';

        titre = 'ELEVES ELIGIBLE A PRENDRE LE BUS (Total: ' + liste.length + ')';
        widths = ['auto', '*', 'auto', 'auto'];
        // tslint:disable-next-line:max-line-length
        body.push([{ text: 'N°', bold: true, fontSize: 12, color: '#fff' }, { text: 'Nom & Prenom', bold: true, fontSize: 12, color: '#fff' }, { text: 'Destination', bold: true, fontSize: 12, color: '#fff' }, { text: 'Bus', bold: true, fontSize: 12, color: '#fff' }]);

        liste.forEach((value, key) => {
          // tslint:disable-next-line:max-line-length
          body.push([(key + 1), { text: value.eleve_nom + ' ' + (value.eleve_prenom ? value.eleve_prenom : ''), alignment: 'left' }, value.destination, value.code]);
        });

        // tslint:disable-next-line:max-line-length
        // body.push([{ text: ' ', fillColor: '#000000' }, { text: ' ', bold: true, fillColor: '#000000', color: '#fff' }, { text: ' ', bold: true, fillColor: '#000000', color: '#fff' }, { text: '', bold: true, fillColor: '#000000', color: '#fff' }, { text: 'Totaux montant', bold: true, fillColor: '#000000', color: '#fff' }, { text: totalpaye + " FCFA", bold: true, color: 'blue', alignment: 'right' }, { text: '', bold: true, fillColor: '#000000', color: '#fff' }, { text: '', bold: true, fillColor: '#000000', color: '#fff' }]);
      } else if (type === 5) {

        orientation = 'landscape';

        titre = 'SALAIRE DES PERSONNELS PERMANANTS';
        widths = ['*', '*', '*', '*'];
        // tslint:disable-next-line:max-line-length
        body.push([{ text: 'Nom & Prenom', bold: true, fontSize: 12, color: '#fff' }, { text: 'Salaire', bold: true, fontSize: 12, color: '#fff' }, { text: 'Santion', bold: true, fontSize: 12, color: '#fff' }, { text: 'Salaire total', bold: true, fontSize: 12, color: '#fff' }]);

        liste.forEach((value, key) => {
          // tslint:disable-next-line:radix
          total += parseInt(value.total);
          // tslint:disable-next-line:max-line-length
          body.push([{ text: value.civilite + '. ' + value.prenom + ' ' + value.prenom, alignment: 'left' }, { text: value.salaire_mensuel + ' FCFA', alignment: 'right' }, { text: value.santion + ' FCFA', alignment: 'right' }, { text: value.total + ' FCFA', alignment: 'right' }]);
        });

        // tslint:disable-next-line:max-line-length
        body.push([{ text: ' ', fillColor: '#000000' }, { text: 'Total', fillColor: '#000000', color: '#fff' }, { text: total + ' FCFA', bold: true, color: 'blue', alignment: 'right' }, { text: '', bold: true, fillColor: '#000000' }]);

      } else if (type === 6) {

        orientation = 'landscape';

        titre = 'SALAIRE DES PERSONNELS VACATAIRES';
        widths = ['*', '*', 'auto', '*', '*'];
        // tslint:disable-next-line:max-line-length
        body.push([{ text: 'Nom & Prenom', bold: true, fontSize: 12, color: '#fff' }, { text: 'Salaire', bold: true, fontSize: 12, color: '#fff' }, { text: 'Volume horaire', bold: true, fontSize: 12, color: '#fff' }, { text: 'Santion', bold: true, fontSize: 12, color: '#fff' }, { text: 'Salaire total', bold: true, fontSize: 12, color: '#fff' }]);

        liste.forEach((value, key) => {
          // tslint:disable-next-line:max-line-length
          // tslint:disable-next-line:radix
          total += parseInt(value.total);
          // tslint:disable-next-line:max-line-length
          body.push([{ text: value.civilite + '. ' + value.prenom + ' ' + value.prenom, alignment: 'left' }, { text: value.salaire_mensuel + ' FCFA', alignment: 'right' }, { text: value.volume_horaire + 'H', alignment: 'right' }, { text: value.santion + ' FCFA', alignment: 'right' }, { text: value.total + ' FCFA', alignment: 'right' }]);
        });

        // tslint:disable-next-line:max-line-length
        body.push([{ text: ' ', fillColor: '#000000' }, { text: ' ', fillColor: '#000000' }, { text: '', bold: true, fillColor: '#000000' }, { text: 'Total', fillColor: '#000000', color: '#fff' }, { text: total + ' FCFA', bold: true, color: 'blue', alignment: 'right' }]);

      }


      const docDefinition = {
      pageSize: 'A4',

      pageOrientation: orientation,
      pageMargins: [30, 60, 30, 30],
      // tslint:disable-next-line:max-line-length
      footer(currentPage, pageCount) { return { margin: [30, 10, 30, 10], text: 'Page ' + currentPage.toString() + ' sur ' + pageCount, fontSize: 8 }; },
      header(currentPage, pageCount) {
        return {
            // tslint:disable-next-line:max-line-length
            image,
              fit: [60, 60]
         , alignment: (currentPage % 2) ? 'center' : 'center' };
      },
      info: {
        title: titre,
        author: 'SILDY Sn',
        subject: titre,
        keywords: 'administration, sildy.pro',
        producer: 'SkullSpace',
        modDate: new Date().toLocaleDateString(),
      },
      content: [
        { text: titre, bold: true, fontSize: 18, lineHeight: 2, alignment: 'center'},
        { text: annee, bold: true, fontSize: 16, lineHeight: 1.5, alignment: 'center' },
        { text: classe, bold: true, fontSize: 14, lineHeight: 2, alignment: 'center' },
        {
          alignment: 'center',
          layout: {// 'lightHorizontalLines',
            fillColor(i, node) {
              if (i > 0) {

                return (i % 2 === 0) ? '#CCCCCC' : null;
              } else {
                return '#000000';
              }
            }
          },

          hLineWidth(i, node) {
            return (i === 0 || i === node.table.body.length) ? 2 : 1;
          },
          vLineWidth(i, node) {
            return (i === 0 || i === node.table.widths.length) ? 2 : 1;
          },
          hLineColor(i, node) {
            return (i === 0 || i === node.table.body.length) ? 'black' : 'gray';
          },
          vLineColor(i, node) {
            return (i === 0 || i === node.table.widths.length) ? 'black' : 'gray';
          },
          table: {
          // headers are automatically repeated if the table spans over multiple pages
          // you can declare how many rows should be treated as headers
          headerRows: 1,
          widths,
          body,
        }

      }
      ]
    };
      pdfMake.createPdf(docDefinition).open();
    });
  }

  imprimeEtat(param) {
    this.toDataURL(this.img, (image) => {
      this.docDefinition = {
        // tslint:disable-next-line:max-line-length
        footer(currentPage, pageCount) { return { margin: [30, 10, 30, 10], text: 'Page ' + currentPage.toString() + ' sur ' + pageCount, fontSize: 8 }; },
        info: {
          title: 'Etat de l\'élève ' + param.Eleve.nom + ' ' + (param.Eleve.prenom ? param.Eleve.prenom : ''),
          author: 'SILDY Sn',
          subject: 'Etat de l\'élève ' + param.Eleve.nom + ' ' + (param.Eleve.prenom ? param.Eleve.prenom : ''),
          keywords: 'administration, sildy.pro',
          producer: 'SkullSpace',
          modDate: new Date().toLocaleDateString(),
        },
        content: [
          // columns of head
          {
            columns: [
              {
                width: '*',
                stack: [

                  { text: this.manager.ecole.nom_fr, alignment: 'center', bold: true, fontSize: 8, lineHeight: 2 },
                  { text: this.manager.ecole.libelle, alignment: 'center', bold: true, fontSize: 8, lineHeight: 2 },
                  { text: this.manager.ecole.nom_en, alignment: 'center', bold: true, fontSize: 7 },

                ],


              },
              {
                width: '*',
                stack: [
                  {
                    // tslint:disable-next-line:max-line-length
                    image,
                    fit: [65, 65],
                    alignment: 'center'
                  }
                ]

              },
              {
                width: '*',
                alignment: 'center',
                stack: [
                  { text: 'SERVICE DE LA SCOLARITE ', fontSize: 9, bold: true, lineHeight: 2 },
                  { text: 'REGISTRATION SERVICE ', fontSize: 8, bold: true, lineHeight: 2 },
                  { text: 'Tél: ' + this.manager.ecole.tel2 + ' | Email: ' + this.manager.ecole.email + '', fontSize: 7, bold: true },
                ],
              },
            ]
          },
          { text: ' ', lineHeight: 3 },
          {
            width: '*',
            bold: true,
            alignment: 'center',
            fontSize: 10,
            stack: [
              { text: 'Nom et prenom: ' + param.Eleve.nom + ' ' + (param.Eleve.prenom ? param.Eleve.prenom : '') },
              { text: 'Matricule : ' + param.Eleve.matricule },
              { text: 'Année scolaire: ' + param.Eleve.annee },
              { text: 'Classe: ' + param.Eleve.libelle },
            ]
          },
          { text: ' ', lineHeight: 3 },
          { text: 'Etat global', bold: true, fontSize: 13 },
          { text: ' ', fontSize: 4, alignment: 'center' },
          {
            fontSize: 11,
            layout: 'lightHorizontalLines', // optional
            table: {
              // headers are automatically repeated if the table spans over multiple pages
              // you can declare how many rows should be treated as headers
              // headerRows: 1,
              widths: ['*', '*'],

              body: [
                [{ text: 'Net à payer', bold: true }, param.totalAttendu + ' F CFA'],
                [{ text: 'Montant versé', bold: true }, param.totalVerser + ' F CFA'],
                [{ text: 'Reste', bold: true }, param.totalReste + ' F CFA'],
                //AppModules.Scolarite.FraisScolaire.Reductions.actif?[{ text: 'Réduction', bold: true }, param.reduction + ' F CFA']:[],
                //AppModules.Scolarite.FraisScolaire.Transport.actif?[{ text: 'Transport', bold: true }, param.transport + ' F CFA']:[],
               // AppModules.Scolarite.FraisScolaire.FraisMedicaux.actif?[{ text: 'Frais médicaux', bold: true },  param.Eleve.assurance_medicale == "1" ? '5 000 F CFA': '0 F CFA']:['']
              ]
            }
          },
          { text: ' ', lineHeight: 3 },
          {
                stack: [
                  { text: 'Etat détaillé', bold: true, fontSize: 13 },
                  { text: ' ', fontSize: 4, alignment: 'center' },
                  {
                    fontSize: 11,
                    layout: 'lightHorizontalLines', // optional
                    table: {
                      // headers are automatically repeated if the table spans over multiple pages
                      // you can declare how many rows should be treated as headers
                      headerRows: 1,
                      widths: ['*', '*', '*', '*'],

                      body: [
                        // tslint:disable-next-line:max-line-length
                        [{ text: 'Eléments', bold: true }, { text: 'Attendu', bold: true }, { text: 'Versé', bold: true }, { text: 'Reste', bold: true }],
                        [{ text: 'Inscription', bold: true }, param.inscription.attendu, param.inscription.verser, param.inscription.reste],
                        [{ text: '1ère tranche', bold: true }, param.tranche1.attendu, param.tranche1.verser, param.tranche1.reste],
                        [{ text: '2ème tranche', bold: true }, param.tranche2.attendu, param.tranche2.verser, param.tranche2.reste],
                        [{ text: '3ème tranche', bold: true }, param.tranche3.attendu, param.tranche3.verser, param.tranche3.reste],
                      ]
                    }
                  }
                ],
                width: '*',
          },
        ]};
      pdfMake.createPdf(this.docDefinition).open();
    });
  }

  imprimeCertifica(param) {
    this.toDataURL(this.img, (image) => {
      this.docDefinition = {
        footer(currentPage, pageCount) { return ''; },
        info: {
          title: 'Certificat de scolarité de l\'élève ' + param.nom + ' ' + (param.prenom ? param.prenom : ''),
          author: 'SILDY Sn',
          subject: 'Certificat de scolarité de l\'élève ' + param.nom + ' ' + (param.prenom ? param.prenom : ''),
          keywords: 'administration, sildy.pro',
          producer: 'SkullSpace',
          modDate: new Date().toLocaleDateString(),
        },
        content: [
          // columns of head
          {
            columns: [
              {
                width: 200,
                stack: [
                  { text: 'REPUBLIQUE DU CAMEROUN', alignment: 'center', bold: true, fontSize: 9, lineHeight: 2 },
                  { text: 'Paix - Travail - Patrie ', alignment: 'center', bold: true, fontSize: 7, lineHeight: 2 },
                  { text: 'Ministère de l\'Education de ' + this.manager.ecole.ministere_fr, alignment: 'center', bold: true, fontSize: 9 },
                ],
              },
              {
                width: '*',
                stack: [
                  {
                    // tslint:disable-next-line:max-line-length
                    image,
                    fit: [65, 65],
                    alignment: 'center'
                  }
                ]

              },
              {
                width: 200,
                stack: [
                  { text: 'REPUBLIC OF CAMEROON', alignment: 'center', bold: true, fontSize: 9, lineHeight: 2 },
                  { text: 'Peace - Work - Fatherland ', alignment: 'center', bold: true, fontSize: 7, lineHeight: 2 },
                  { text: 'Ministry of ' + this.manager.ecole.ministere_en + ' Education', alignment: 'center', bold: true, fontSize: 9 },
                ],
              },
            ],
            columnGap: 20,
          },
          { text: ' ', lineHeight: 2 },
          { text: this.manager.ecole.nom_fr, alignment: 'center', fontSize: 10 },
          { text: this.manager.ecole.libelle, alignment: 'center', fontSize: 13, bold: true},
          { text: this.manager.ecole.nom_en, alignment: 'center', fontSize: 10  },
          { text: this.manager.ecole.authorisation, alignment: 'center', fontSize: 8 , italics: true, lineHeight: 3 },
          {
            width: 'auto',
            alignment: 'center',
            stack: [
              { text: 'CERTIFICAT DE SCOLARITE ', fontSize: 9, bold: true},
              { text: 'SCHOOL ATTENDANCE CERTIFICATE ', fontSize: 7, bold: true, lineHeight: 3 , italics: true},
            ],
          },
          {
            margin: [30, 10, 30, 10],
            width: 'auto',
            stack: [
              { text: 'Nous soussigné ' + this.manager.ecole.directeur, fontSize: 10, bold: true, },
              { text: 'I the undersigned,  ', fontSize: 9, italics: true, lineHeight: 3 },
              { text: 'Directeur du  ' + this.manager.ecole.sigle_fr + ' ' + this.manager.ecole.libelle + ' ', fontSize: 10, bold: true},
              // tslint:disable-next-line: max-line-length
              { text: 'Director of  ' + this.manager.ecole.libelle + ' ' + this.manager.ecole.sigle_en , fontSize: 9, italics: true, lineHeight: 3 },
              { text: 'Attestons que ' + param.nom + ' ' + (param.prenom ? param.prenom : ''), fontSize: 10, bold: true, },
              { text: 'Certifie that ', fontSize: 9, italics: true, lineHeight: 3 },
              {
                columns: [
                  {
                    width: 200,
                    stack: [
                      // tslint:disable-next-line:max-line-length
                      { text: 'Né(e) le  ' + (new Date(param.date_naiss).getDate() + 1) + '-' + (new Date(param.date_naiss).getMonth() + 1)  + '-' + new Date(param.date_naiss).getFullYear(), fontSize: 10, bold: true },
                      { text: 'Born the ', italics: true, fontSize: 9}
                    ],
                  },
                  {
                    width: 200,
                    stack: [
                      { text: 'à ' + param.lieu_naiss, fontSize: 10, bold: true},
                      { text: 'At ', italics: true, fontSize: 9 }
                    ],
                  },
                ]
              },
              { text: ' ', lineHeight: 2 },
              { text: 'est régulièrement inscrit(e) dans notre école en classe de :  ' + param.libelle  , fontSize: 10, bold: true  },
              { text: 'is regularly enrolled in our scholl in '  , fontSize: 9, italics: true, lineHeight: 3},
              { text: 'Année académique : ' + param.annee, fontSize: 10, bold: true,  },
              { text: 'Academic year ', fontSize: 9, italics: true, lineHeight: 3 },
              { text: 'En foi de quoi le présent certificat lui est délivré pour servir et valoir ce que de droit. '  , fontSize: 10 },
              // tslint:disable-next-line:max-line-length
              { text: 'In faith whereof the present certificate is delivered to him/her to serve and assert what is right.  ' , fontSize: 9, italics: true, lineHeight: 4 },
              // tslint:disable-next-line:max-line-length
              { alignment: 'right', text: 'Fait à douala le ' + new Date().getDate() + '/' + new Date().getMonth() + '/' + new Date().getFullYear(), fontSize: 9, italics: true, lineHeight: 2  },
              { text: 'Le Directeur ' , fontSize: 12, bold: true, alignment: 'right' },

            ],
          },
        ]
      };
      pdfMake.createPdf(this.docDefinition).open();
    });

  }

  imprimerTransport(param) {
    this.toDataURL(this.img, (image) => {
      this.docDefinition.content = [
        {
          columns: [
            {
              width: '40%',
              stack: [

                { text: this.manager.ecole.nom_fr, alignment: 'center', bold: true, fontSize: 8, lineHeight: 2 },
                { text: this.manager.ecole.libelle, alignment: 'center', bold: true, fontSize: 8, lineHeight: 2 },
                { text: this.manager.ecole.nom_en, alignment: 'center', bold: true, fontSize: 7 },

              ],
            },
            {
              width: '25%',
              stack: [
                {
                  // tslint:disable-next-line:max-line-length
                  image,
                  fit: [65, 65],
                  alignment: 'center'
                }
              ]

            },
            {
              width: '35%',
              alignment: 'center',
              stack: [
                { text: 'SERVICE DE LA SCOLARITE ', fontSize: 9, bold: true, lineHeight: 2 },
                { text: 'REGISTRATION SERVICE ', fontSize: 8, bold: true, lineHeight: 2},
                { text: 'Tél:' + this.manager.ecole.tel1 + ' | Email: ' + this.manager.ecole.email + '', fontSize: 6, bold: true },
              ],

            },
          ]
        },
        { text: 'RECU / RECEIPT No ' + param.numero, alignment: 'center', bold: true, fontSize: 10 },
        { text: 'FRAIS DE TRANSPORT / TRANSPORT FEES', alignment: 'center', fontSize: 9 },
        { text: ' ', fontSize: 4, alignment: 'center', lineHeight: 4 },

        {
          columns: [
            {
              width: '13%',
              alignment: 'left',
              stack: [
                ' '
              ]

            },
            {
              width: 'auto',
              bold: true,
              alignment: 'left',
              fontSize: 8,
              stack: [
                { text: 'Matricule:', },
                { text: 'Nom(s):' },
                { text: 'Prenom(s): ' },
                { text: 'Classe:' },

              ]

            },

            {
              width: 'auto',
              alignment: 'left',
              fontSize: 8,
              stack: [
                { text: param.matricule },
                { text: param.eleve_nom },
                { text: (param.eleve_prenom) ? param.eleve_prenom : '' },
                { text: param.classe },
              ]

            },
            {
              width: '30%',
              alignment: 'left',
              stack: [
                ' '
              ]

            },
            {
              width: 'auto',
              bold: true,
              alignment: 'left',
              fontSize: 8,
              stack: [
                'Net à payer: ',
                'Déjà payer: ',
                { text: 'Rester à payer: ' },
              ]

            },
            {
              width: 'auto',
              alignment: 'left',
              fontSize: 8,
              stack: [

                { text: param.montant_init + ' F CFA' },
                { text: param.montant + ' F CFA' },
                { text: (param.montant_init - param.montant) + ' F CFA' },


              ]

            },

          ],
          columnGap: 10,
        },
        { text: ' ', fontSize: 3, alignment: 'center' },

        {
          columns: [
            {
              width: '33%',
              alignment: 'left',
              stack: [
                ' '
              ]

            },
            {
              width: '*',
              alignment: 'left',
              stack: [
                { text: 'DETAILS DU TRANSPORT', bold: true, fontSize: 9, alignment: 'center', },
                { text: ' ', fontSize: 4, alignment: 'center' },
                {
                  fontSize: 8,
                  alignment: 'center',
                  // layout: 'lightHorizontalLines', // optional
                  table: {
                    // headers are automatically repeated if the table spans over multiple pages
                    // you can declare how many rows should be treated as headers
                    // headerRows: 1,
                    widths: ['*', '*'],

                    body: [
                      // tslint:disable-next-line:max-line-length
                      [{ text: 'Date debut', bold: true }, param.date_debut],
                      [{ text: 'Date fin', bold: true }, param.date_fin],
                      [{ text: 'Destination', bold: true }, param.destination]
                    ]
                  }
                }
              ]
            },

            {
              width: '*',
              alignment: 'left',
              fontSize: 8,
              stack: [
                { text: 'SIGNATURE', bold: true },
              ]

            },

          ],
          columnGap: 60,
        },

        { text: ' ', bold: true, fontSize: 4},
        // tslint:disable-next-line:max-line-length
        { alignment: 'center', text: 'NB : Les droits d\'inscription et les fais de scolarité ne sont ni remboursables ni substituables.', fontSize: 6 },
        ' ',
        // tslint:disable-next-line:max-line-length
        '-----------------------------------------------------------------------------------------------------------------------------------------------------------',
        {
          columns: [
            {
              width: '40%',
              stack: [

                { text: this.manager.ecole.nom_fr, alignment: 'center', bold: true, fontSize: 8, lineHeight: 2 },
                { text: this.manager.ecole.libelle, alignment: 'center', bold: true, fontSize: 8, lineHeight: 2 },
                { text: this.manager.ecole.nom_en, alignment: 'center', bold: true, fontSize: 7 },

              ],


            },
            {
              width: '25%',
              stack: [
                {
                  // tslint:disable-next-line:max-line-length
                  image,
                  fit: [65, 65],
                  alignment: 'center'
                }
              ]

            },
            {
              width: '35%',
              alignment: 'center',
              stack: [
                { text: 'SERVICE DE LA SCOLARITE ', fontSize: 9, bold: true, lineHeight: 2 },
                { text: 'REGISTRATION SERVICE ', fontSize: 8, bold: true, lineHeight: 2},
                { text: 'Tél:' + this.manager.ecole.tel1 + ' | Email: ' + this.manager.ecole.email + '', fontSize: 6, bold: true },
              ],

            },
          ]
        },
        { text: 'RECU / RECEIPT No ' + param.numero, alignment: 'center', bold: true, fontSize: 10 },
        { text: 'FRAIS DE TRANSPORT / TRANSPORT FEES', alignment: 'center', fontSize: 9 },
        { text: ' ', fontSize: 4, alignment: 'center', lineHeight: 4 },

        {
          columns: [
            {
              width: '13%',
              alignment: 'left',
              stack: [
                ' '
              ]

            },
            {
              width: 'auto',
              bold: true,
              alignment: 'left',
              fontSize: 8,
              stack: [
                { text: 'Matricule:', },
                { text: 'Nom(s):' },
                { text: 'Prenom(s): ' },
                { text: 'Classe:' },

              ]

            },

            {
              width: 'auto',
              alignment: 'left',
              fontSize: 8,
              stack: [
                { text: param.matricule },
                { text: param.eleve_nom },
                { text: (param.eleve_prenom) ? param.eleve_prenom : '' },
                { text: param.classe },
              ]

            },
            {
              width: '30%',
              alignment: 'left',
              stack: [
                ' '
              ]

            },
            {
              width: 'auto',
              bold: true,
              alignment: 'left',
              fontSize: 8,
              stack: [
                'Net à payer: ',
                'Déjà payer: ',
                { text: 'Rester à payer: ' },
              ]

            },
            {
              width: 'auto',
              alignment: 'left', fontSize: 8,
              stack: [

                { text: param.montant_init + ' F CFA' },
                { text: param.montant + ' F CFA' },
                { text: (param.montant_init - param.montant) + ' F CFA' },


              ]

            },

          ],
          columnGap: 10,
        },
        { text: ' ', fontSize: 3, alignment: 'center' },
        {
          columns: [
            {
              width: '33%',
              alignment: 'left',
              stack: [
                ' '
              ]

            },
            {
              width: '*',
              alignment: 'left',
              stack: [
                { text: 'DETAILS DU TRANSPORT', bold: true, fontSize: 9, alignment: 'center', },
                { text: ' ', fontSize: 4, alignment: 'center' },
                {
                  alignment: 'center',
                  fontSize: 8,
                  // layout: 'lightHorizontalLines', // optional
                  table: {
                    // headers are automatically repeated if the table spans over multiple pages
                    // you can declare how many rows should be treated as headers
                    // headerRows: 1,
                    widths: ['*', '*'],

                    body: [
                      // tslint:disable-next-line:max-line-length
                      [{ text: 'Date debut', bold: true }, param.date_debut],
                      [{ text: 'Date fin', bold: true }, param.date_fin],
                      [{ text: 'Destination', bold: true }, param.destination]
                    ]
                  }
                },

              ]

            },

            {
              width: '*',
              alignment: 'left',
              fontSize: 8,
              stack: [
                { text: 'SIGNATURE', bold: true },
              ]

            },

          ],
          columnGap: 60,
        },

          { text: ' ', bold: true, fontSize: 4},
        // tslint:disable-next-line:max-line-length
        { alignment: 'center', text: 'NB : Les droits d\'inscription et les fais de scolarité ne sont ni remboursables ni substituables.', fontSize: 6 },
        ' ',
        // tslint:disable-next-line:max-line-length
        '-----------------------------------------------------------------------------------------------------------------------------------------------------------',
        {
          columns: [
            {
              width: '40%',
              stack: [

                { text: this.manager.ecole.nom_fr, alignment: 'center', bold: true, fontSize: 8, lineHeight: 2 },
                { text: this.manager.ecole.libelle, alignment: 'center', bold: true, fontSize: 8, lineHeight: 2 },
                { text: this.manager.ecole.nom_en, alignment: 'center', bold: true, fontSize: 7 },

              ],


            },
            {
              width: '25%',
              stack: [
                {
                  // tslint:disable-next-line:max-line-length
                  image,
                  fit: [65, 65],
                  alignment: 'center'
                }
              ]

            },
            {
              width: '35%',
              alignment: 'center',
              stack: [
                { text: 'SERVICE DE LA SCOLARITE ', fontSize: 9, bold: true, lineHeight: 2 },
                { text: 'REGISTRATION SERVICE ', fontSize: 8, bold: true, lineHeight: 2},
                { text: 'Tél:' + this.manager.ecole.tel1 + ' | Email: ' + this.manager.ecole.email + '', fontSize: 6, bold: true },
              ],

            },
          ]
        },
        { text: 'RECU / RECEIPT No ' + param.numero, alignment: 'center', bold: true, fontSize: 10 },
        { text: 'FRAIS DE TRANSPORT / TRANSPORT FEES', alignment: 'center', fontSize: 9 },
        { text: ' ', fontSize: 4, alignment: 'center', lineHeight: 4 },

        {
          columns: [
            {
              width: '13%',
              alignment: 'left',
              stack: [
                ' '
              ]

            },
            {
              width: 'auto',
              bold: true,
              alignment: 'left',
              fontSize: 8,
              stack: [
                { text: 'Matricule:', },
                { text: 'Nom(s):' },
                { text: 'Prenom(s): ' },
                { text: 'Classe:' },

              ]

            },

            {
              width: 'auto',
              alignment: 'left',
              fontSize: 8,
              stack: [
                { text: param.matricule },
                { text: param.eleve_nom },
                { text: (param.eleve_prenom) ? param.eleve_prenom : '' },
                { text: param.classe },
              ]

            },
            {
              width: '30%',
              alignment: 'left',
              stack: [
                ' '
              ]

            },
            {
              width: 'auto',
              bold: true,
              alignment: 'left',
              fontSize: 8,
              stack: [
                'Net à payer: ',
                'Déjà payer: ',
                { text: 'Rester à payer: ' },
              ]

            },
            {
              width: 'auto',
              alignment: 'left', fontSize: 8,
              stack: [

                { text: param.montant_init + ' F CFA' },
                { text: param.montant + ' F CFA' },
                { text: (param.montant_init - param.montant) + ' F CFA' },


              ]

            },

          ],
          columnGap: 10,
        },
        { text: ' ', fontSize: 3, alignment: 'center' },
        {
          columns: [
            {
              width: '33%',
              alignment: 'left',
              stack: [
                ' '
              ]

            },
            {
              width: '*',
              alignment: 'left',
              stack: [
                { text: 'DETAILS DU TRANSPORT', bold: true, fontSize: 9, alignment: 'center', },
                { text: ' ', fontSize: 4, alignment: 'center' },
                {
                  alignment: 'center',
                  fontSize: 8,
                  // layout: 'lightHorizontalLines', // optional
                  table: {
                    // headers are automatically repeated if the table spans over multiple pages
                    // you can declare how many rows should be treated as headers
                    // headerRows: 1,
                    widths: ['*', '*'],

                    body: [
                      // tslint:disable-next-line:max-line-length
                      [{ text: 'Date debut', bold: true }, param.date_debut],
                      [{ text: 'Date fin', bold: true }, param.date_fin],
                      [{ text: 'Destination', bold: true }, param.destination]
                    ]
                  }
                },

              ]

            },

            {
              width: '*',
              alignment: 'left',
              fontSize: 8,
              stack: [
                { text: 'SIGNATURE', bold: true },
              ]

            },

          ],
          columnGap: 60,
        },

          { text: ' ', bold: true, fontSize: 4},
        // tslint:disable-next-line:max-line-length
        { alignment: 'center', text: 'NB : Les droits d\'inscription et les fais de scolarité ne sont ni remboursables ni substituables.', fontSize: 6 },
        ' ',
        // tslint:disable-next-line:max-line-length
        '-----------------------------------------------------------------------------------------------------------------------------------------------------------',
      ];
      pdfMake.createPdf(this.docDefinition).open();
    });


  }
}
