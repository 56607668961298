import { Injectable, Inject } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { LOCAL_STORAGE, WebStorageService } from 'angular-webstorage-service';
import { Service } from './service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor( private manager: Service, private router: Router) { }

  userData = localStorage.getItem('ssUser') ? JSON.parse(localStorage.getItem('ssUser') ) : {state: false};

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      return this.userData.state;
      // if (this.manager.currentUser.state) {
      //   return true;
      // } else {
      //   this.router.navigate['login'];
      //   return false;
      // }
  }
}
