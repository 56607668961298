// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  host: 'https://admin.racinesdescompetences.com/',
  baseURI: 'https://api.racinesdescompetences.com/',
  uploadURI: 'https://api.racinesdescompetences.com/',
  img: 'https://api.racinesdescompetences.com/assets/images/logo.png'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
